<script>
import { onBeforeUnmount } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import { useUI } from '/~/composables/ui'

export default {
  name: 'ew-details-header',
  components: {
    BasePlaceholder,
    BaseButton,
  },
  props: {
    title: {
      type: String,
      default: 'Details',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { isHeadroomActive } = useUI()

    isHeadroomActive.value = false

    onBeforeUnmount(() => {
      isHeadroomActive.value = true
    })
  },
  methods: {
    handleScroll({ target: { scrollTop } }) {
      this.shadow = scrollTop > 0
    },
    onBack() {
      if (this.back) {
        this.$router.push(this.back)
      } else {
        this.$router.back()
      }
    },
  },
}
</script>

<template>
  <portal to="app-header">
    <div class="absolute inset-0 z-1 h-full shadow sm:hidden">
      <div class="flex h-full items-center justify-center bg-light">
        <div
          class="absolute left-0 top-0 flex h-full cursor-pointer items-center justify-center px-[15px]"
        >
          <base-button
            ref="backButton"
            icon="base/chevron"
            :size="24"
            :padding="2"
            alt="back"
            @click="onBack"
          />
        </div>
        <base-placeholder
          v-if="loading"
          class="w-40"
          type="text"
          rows="1"
          height="16"
        />
        <div
          v-else
          class="w-7/12 truncate text-center text-lg font-bold text-eonx-neutral-800"
        >
          <span v-html="title" />
        </div>
      </div>
    </div>
  </portal>
</template>
